
/*
 Add Kalpurush font
*/
@font-face {
  font-family: 'Kalpurush';
  src: url("assets/kalpurush.ttf");
}
* {
  font-family : 'Kalpurush' !important;
}
body {
  margin: 0;
  font-family:  'Kalpurush';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  .MuiToolbar-root, .RaSidebar-fixed {
    display: none !important;
  }
  .RaShow-main {
    margin: 0;
  }
  
}